import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

const AcceptPaymentRedirect = () => {
  // Extract path parameters
  const { token, orderid } = useParams();

  useEffect(() => {
    // Validate the path parameters
    if (!token || !orderid) {
      console.error("Missing token or order ID");
      return;
    }

    // Construct the backend URL
    const backendUrl = `${process.env.REACT_APP_API_URL}/buying/accept-payment/nets?token=${token}&orderid=${orderid}`;
    console.log("Redirecting to:", backendUrl);

    // Perform the redirection
    window.location.href = backendUrl;
  }, [token, orderid]);

  // Display a loading spinner while redirecting
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            width: "12px",
            height: "12px",
            backgroundColor: "primary.main",
            borderRadius: "50%",
            animation: "dot-flash 1.4s infinite ease-in-out both",
            animationDelay: "0s",
          }}
        />
        <Box
          sx={{
            width: "12px",
            height: "12px",
            backgroundColor: "primary.main",
            borderRadius: "50%",
            animation: "dot-flash 1.4s infinite ease-in-out both",
            animationDelay: "0.2s",
          }}
        />
        <Box
          sx={{
            width: "12px",
            height: "12px",
            backgroundColor: "primary.main",
            borderRadius: "50%",
            animation: "dot-flash 1.4s infinite ease-in-out both",
            animationDelay: "0.4s",
          }}
        />
      </Box>
      <style>
        {`
          @keyframes dot-flash {
            0%, 80%, 100% {
              opacity: 0;
            }
            40% {
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default AcceptPaymentRedirect;
