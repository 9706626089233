import HttpService from "./http.service";

class ProductService {
  createProduct = async (payload) => {
    const Endpoint = "/product/create";
    return await HttpService.post(Endpoint, payload);
  };
  getProduct = async (id, search, type) => {
    const Endpoint = `/product/fetch/${id}/${search}/${type}`;
    return await HttpService.get(Endpoint);
  };
  getProductById = async (role, id) => {
    const Endpoint = `/product/fetchById/${role}/${id}`;
    return await HttpService.get(Endpoint);
  };
  updateProduct = async (id, payload) => {
    const Endpoint = `/product/update/${id}`;
    return await HttpService.put(Endpoint, payload);
  };
  updateProductStatus = async (payload) => {
    const Endpoint = `/product/updateStatus`;
    return await HttpService.put(Endpoint, payload);
  };
  deleteProduct = async (id) => {
    const Endpoint = `/product/delete/${id}`;
    return await HttpService.delete(Endpoint);
  };
}

export default new ProductService();
