import Axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
Axios.defaults.baseURL = API_URL;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url, onProgress) =>
    await this.request(this.getOptionsConfig("get", url), onProgress);

  post = async (url, data, onProgress) =>
    await this.request(this.getOptionsConfig("post", url, data), onProgress);

  put = async (url, data, onProgress) =>
    await this.request(this.getOptionsConfig("put", url, data), onProgress);

  patch = async (url, data, onProgress) =>
    await this.request(this.getOptionsConfig("patch", url, data), onProgress);

  delete = async (url, onProgress) =>
    await this.request(this.getOptionsConfig("delete", url), onProgress);

  getOptionsConfig = (method, url, data) => {
    return {
      method,
      url,
      data,
      headers: { "Content-Type": "application/vnd.api+json" },
    };
  };

  request(options, onProgress) {
    return new Promise((resolve, reject) => {
      this._axios
        .request({
          ...options,
          onUploadProgress: (progressEvent) => {
            if (onProgress) {
              const percent = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total || 1)
              );
              onProgress(percent);
            }
          },
          onDownloadProgress: (progressEvent) => {
            if (onProgress) {
              const percent = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total || 1)
              );
              onProgress(percent);
            }
          },
        })
        .then((res) => resolve(res?.data))
        .catch((ex) => reject(ex?.response?.data));
    });
  }
}

export default new HttpService();
