import React, { lazy } from 'react';

// Lazy load components
const Sales = lazy(() => import("layouts/sales/Socks"));
const LotterySales = lazy(() => import("layouts/sales/Lottery"));
const Settings = lazy(() => import("layouts/pages/account/settings"));
const AddPrize = lazy(() => import("layouts/add-prize"));
const NewProduct = lazy(() => import("layouts/new-product"));
const AddAdmin = lazy(() => import("layouts/add-admin"));
const AddCodes = lazy(() => import("layouts/add-codes"));
const EditProduct = lazy(() => import("layouts/edit-product"));
const Orders = lazy(() => import("layouts/orders/Socks"));
const LotteryOrders = lazy(() => import("layouts/orders/Lottery"));
const Chat = lazy(() => import("layouts/chat"));
const Prize = lazy(() => import("layouts/prize"));
const PrizeOrders = lazy(() => import("layouts/prize-orders"));
const InviteToCollaborator = lazy(() => import("layouts/InviteToCollaborator"));
const Collaborators = lazy(() => import("layouts/collaborators"));
const ClubLevel = lazy(() => import("layouts/InviteToCollaborator/ClubLevel"));
const TeamLevel = lazy(() => import("layouts/InviteToCollaborator/TeamLevel"));

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/user.webp";

const routes = [
  {
    type: "collapse",
    name: "Bruce Mars",
    key: "user-name",
    icon: <MDAvatar src={profilePicture} alt="Bruce Mars" size="sm" />,
    collapse: [
      {
        name: "My profile",
        key: "settings",
        route: "/account/my",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
      },
    ],
    noArrow: false,
  },
  { type: "divider", key: "divider-0" },
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Analytics />,
  //   noCollapse: true,
  //   noArrow:false,
  // },
  {
    type: "collapse",
    name: "Sales",
    key: "Sales",
    icon: <Icon fontSize="medium">leaderboard</Icon>,
    // collapse: [
    //   {
    //     route: "/sales",
    //     name: "Socks Sales",
    //     key: "sales",
    //     component: <Sales />,
    //   },
    //   {
    //     route: "/lottery-sales",
    //     name: "Lottery Sales",
    //     key: "lottery-sales",
    //     component: <LotterySales />,
    //   },
    // ],
    // noArrow: false,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "Orders",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    // collapse: [
    //   {
    //     route: "/orders",
    //     name: "Socks Orders",
    //     key: "orders",
    //     component: <Orders />,
    //   },
    //   {
    //     route: "/lottery-orders",
    //     name: "Lottery Orders",
    //     key: "lottery-orders",
    //     component: <LotteryOrders />,
    //   },
    // ],
    // noArrow: false,
  },
  {
    type: "collapse",
    name: "Chat",
    key: "chat",
    icon: <Icon fontSize="medium">forum</Icon>,
    route: "/chat",
    component: <Chat />,
    noCollapse: true,
    noArrow: false,
  },
  {
    //type: "collapse",
    name: "inviteMembers",
    icon: <Icon fontSize="medium">view_timeline</Icon>,
    key: "invite_to_collaborator",
    route: "",
    component: <InviteToCollaborator />,
    noCollapse: true,
    noArrow: false,
  },
  {
    type: "collapse",
    name: "Club Hub",
    icon: <Icon fontSize="medium">group</Icon>,
    noCollapse: true,
    noArrow: false,
    key: "collaborators",
    route: "/collaborators/:id",
    component: <Collaborators />,
  },
  {
    // type: "collapse",
    // name: "Invite Collaborator",
    // icon: <InsertInvitation />,
    // noArrow:false,
    key: "invite_collaborator",
    route: "/invite-collaborator/:id/:name/:lang",
    component: <InviteToCollaborator />,
    noCollapse: true,
  },
  {
    route: "/club-level/:id/:name/:lang",
    name: 'UploadRegister',
    key: "UploadRegister",
    component: <ClubLevel />,
    noCollapse: true,
  },
  {
    route: "/team-level/:id/:name/:lang",
    name: 'ShareInviteLink',
    key: "ShareInviteLink",
    component: <TeamLevel />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Prize",
    key: "prize",
    icon: <Icon fontSize="medium">redeem</Icon>,
    route: "/prize",
    component: <Prize />,
    noCollapse: true,
    noArrow: false,
  },
  {
    type: "collapse",
    name: "Prize orders",
    key: "prize-orders",
    icon: <Icon fontSize="medium">list_alt</Icon>,
    route: "/prize-orders",
    component: <PrizeOrders />,
    noCollapse: true,
    noArrow: false,
  },
  {
    key: "new-form",
    route: "/new-form",
    component: <NewProduct />,
  },
  {
    key: "add-admin",
    route: "/add-admin",
    component: <AddAdmin />,
  },
  {
    key: "add-prize",
    route: "/add-prize",
    component: <AddPrize />,
  },
  {
    key: "add-codes",
    route: "/add-codes/:id",
    component: <AddCodes />,
  },
  {
    key: "edit-product",
    route: "/edit-product/:productId/:typ",
    component: <EditProduct />,
  },
  {
    key: "duplicate-product",
    route: "/duplicate-product/:productId/:typ",
    component: <EditProduct type='duplicate' />,
  },
  {
    key: "add-prize",
    route: "/edit-prize/:prizeId",
    component: <AddPrize />,
  },
  //{ type: "title", title: "Team", key: "team" },
  {
    type: "collapse",
    name: "Team",
    key: "teem",
    icon: <Icon fontSize="medium">diversity_3</Icon>,
    collapse: [],
  },
];

export default routes;
